import { AutoComplete, Select, Card, Space } from "antd";
import { AxiosPromise, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { userProvinceMap } from "@/lib/types/constants";
import physicianAPIClient from "@/api/PhysicianAPIClient";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { AddressBookPhysician } from "@/lib/types/addressBook";
import { PhysicianLocationModel } from "@/lib/types/physician";
import { toast } from "sonner";

const { Option } = Select;

const getPhysicianLabel = (physician: AddressBookPhysician) => {
  let label = "";
  if (physician.firstName && physician.lastName) {
    label += `${physician.lastName}, ${physician.firstName}`;
  }
  return { label, value: physician.id };
};

export const getPhysicianName = (physician: AddressBookPhysician) => {
  return `${physician?.lastName}, ${physician?.firstName}`;
};

export const getPhysicianFax = (physician: AddressBookPhysician) => {
  return physician?.locations &&
    physician?.locations.length > 0 &&
    physician?.locations[0]?.faxNumbers &&
    physician?.locations[0]?.faxNumbers[0]?.faxNumber
    ? physician?.locations[0]?.faxNumbers[0]?.faxNumber
    : null;
};

export const getPhysicianLocation = (location: PhysicianLocationModel) => {
  return `${location.streetAddress ? `${location.streetAddress}, ` : ""}${location.city ? `${location.city} ` : ""}${
    location.province ? userProvinceMap[location.province] : ""
  }${location.postal ? `, ${location.postal}` : ""}`;
};

export const getPhysicianLocationPhone = (location: PhysicianLocationModel) => {
  let phoneNumber = location?.phoneNumbers?.find((phone) => phone.preferredPhone === true)?.phoneNumber;

  if (phoneNumber === undefined && location?.phoneNumbers && location?.phoneNumbers.length > 0) {
    phoneNumber = location?.phoneNumbers[0]?.phoneNumber;
  }

  return phoneNumber ? phoneNumber : "";
};

export const getPhysicianLocationFax = (location: PhysicianLocationModel) => {
  let faxNumber = location?.faxNumbers?.find((fax) => fax.preferredFax === true)?.faxNumber;

  if (faxNumber === undefined && location?.faxNumbers && location?.faxNumbers.length > 0) {
    faxNumber = location?.faxNumbers[0]?.faxNumber;
  }

  return faxNumber ? faxNumber : "";
};

export const getDefaultReferingPhysician = async (id: number, callback: Function) => {
  const addressBookPhysicianResponse: AxiosResponse = await physicianAPIClient.getAddressBookPhysician(String(id));
  if (addressBookPhysicianResponse.status !== 200) {
    toast.error("Something went wrong while fetching Physician");
    return;
  }
  const physician: AddressBookPhysician = addressBookPhysicianResponse?.data?.physician;
  callback(physician);
};

export async function getPhysician(id: number): Promise<AddressBookPhysician | undefined> {
  const addressBookPhysicianResponse: AxiosResponse = await physicianAPIClient.getAddressBookPhysician(String(id));
  if (addressBookPhysicianResponse.status !== 200) {
    toast.error("Something went wrong while fetching Physician");
    return Promise.resolve(undefined);
  }
  return Promise.resolve(addressBookPhysicianResponse?.data?.physician);
}

interface PhysicianDropdownProps {
  defaultId?: number | null;
  defaultLocationId?: string;
  width?: number;
  onChange?: (arg1: AddressBookPhysician, arg2?: PhysicianLocationModel) => void;
  className?: string;
  size?: SizeType;
  dropdownWidth?: boolean | number;
  listHeight?: number;
  placeholder?: string;
}

export const PhysicianDropdownLegacy = ({
  defaultId,
  width,
  onChange,
  className,
  size,
  dropdownWidth,
  listHeight,
  placeholder,
}: PhysicianDropdownProps) => {
  const [physiciansFound, setPhysiciansFound] = useState<AddressBookPhysician[]>();
  const [selectedPhysician, setSelectedPhysician] = useState<AddressBookPhysician>();
  const [selectedPhysicianLabel, setSelectedPhysicianLabel] = useState(selectedPhysician ? getPhysicianLabel(selectedPhysician).label : "");

  useEffect(() => {
    const getPharmacy = async () => {
      if (defaultId && defaultId > -1) {
        getDefaultReferingPhysician(defaultId, (physician: AddressBookPhysician) => {
          if (!physician) {
            return;
          }
          const physicianElasticType: AddressBookPhysician = {
            id: physician.id,
            firstName: physician.firstName,
            lastName: physician.lastName,
            licensingNumber: physician.licensingNumber,
            billingNumber: physician.billingNumber,
            specialties: physician.specialties,
            locations: physician.locations,
            status: physician.status,
            itemType: physician.itemType,
            notes: physician.notes,
          };
          setSelectedPhysician(physicianElasticType);
          setSelectedPhysicianLabel(getPhysicianLabel(physicianElasticType).label);
        });
      } else {
        setSelectedPhysicianLabel("");
      }
    };
    getPharmacy();
  }, [defaultId, setSelectedPhysician, setSelectedPhysicianLabel]);

  const handleSearch = async (searchText: string) => {
    setSelectedPhysicianLabel(searchText);
    physicianAPIClient.getPhysiciansElastic(searchText, async (axiProm: AxiosPromise) => {
      const result = await axiProm;
      const physiciansSrc = result?.data?.physicians?.hits?.hits;
      const physicians: AddressBookPhysician[] = physiciansSrc.map((p: { _source: AddressBookPhysician }) => p._source);

      setPhysiciansFound(physicians);
    });
  };

  const onClear = () => {
    setSelectedPhysicianLabel("");
    setSelectedPhysician({} as AddressBookPhysician);
    setPhysiciansFound([]);
    if (onChange) {
      onChange({} as AddressBookPhysician, {} as PhysicianLocationModel);
    }
  };

  return (
    <AutoComplete
      size={size}
      className={className}
      style={{ width }}
      value={selectedPhysicianLabel}
      popupMatchSelectWidth={dropdownWidth}
      listHeight={listHeight}
      onSearch={handleSearch}
      placeholder={placeholder ?? "Physician search"}
      onSelect={(_: any, params: any) => {
        const paramsArr = params.key.split(",");
        const physicianSelected = physiciansFound?.find((physician) => physician.id === Number(paramsArr[0]));
        if (!physicianSelected) {
          toast.error("Failed selecting physician.");
          return;
        }
        let locationSelected;
        if (physicianSelected?.locations && physicianSelected?.locations?.length > 0 && paramsArr[1].length > 0) {
          locationSelected = physicianSelected.locations.find((location) => location.id === String(paramsArr[1]));
        }
        setPhysiciansFound([]);

        const { label } = getPhysicianLabel(physicianSelected);
        setSelectedPhysicianLabel(label || "");
        setSelectedPhysician(physicianSelected);
        if (onChange) {
          onChange(physicianSelected, locationSelected);
        }
      }}
      allowClear={true}
      onClear={onClear}
    >
      {physiciansFound ? (
        physiciansFound
          .filter((physician: AddressBookPhysician) => physician?.locations && physician.locations.length > 0)
          .map((physician: AddressBookPhysician) =>
            physician
              .locations!.filter((location: PhysicianLocationModel) => location.id !== undefined && location.active)
              .map((location: PhysicianLocationModel) => (
                <Option
                  key={`${physician.id},${location.id}`}
                  value={`${physician.id},${location.id}`}
                >
                  <Card
                    size="small"
                    className="m-0"
                  >
                    <Space
                      size="small"
                      direction="vertical"
                    >
                      <div className="text-bold">{`${physician?.lastName}, ${physician?.firstName}`}</div>
                      <div>{location?.name}</div>
                      <div>{[location?.streetAddress, `${location?.city} ${location?.province}`, location?.postal].join(", ")}</div>
                      <div>{`P: ${getPhysicianLocationPhone(location)}`}</div>
                      <div>{`F: ${getPhysicianLocationFax(location)}`}</div>
                    </Space>
                  </Card>
                </Option>
              ))
          )
      ) : (
        <></>
      )}
    </AutoComplete>
  );
};

interface PhysicianCardProps {
  physician?: AddressBookPhysician;
  location?: PhysicianLocationModel;
  className?: string | undefined;
}

export const PhysicianCard = ({ physician, location, className }: PhysicianCardProps) => {
  return physician?.firstName && location ? (
    <>
      <div className={className}>
        <div className={`m-0 font-bold ${!location?.active ? "text-red-500" : ""}`}>{`${physician.lastName}, ${physician.firstName}`}</div>
        <div className={`m-0 ${!location?.active ? "text-red-500" : ""}`}>{location.name}</div>
        <div className={`m-0 ${!location?.active ? "text-red-500" : ""}`}>
          {`${location.streetAddress}, ${location.city} ${location.province ? userProvinceMap[location.province] : ""}, ${location.postal}`}
        </div>
        <div className={`m-0 ${!location?.active ? "text-red-500" : ""}`}>{`P: ${getPhysicianLocationPhone(location)}`}</div>
        <div className={`m-0 ${!location?.active ? "text-red-500" : ""}`}>{`F: ${getPhysicianLocationFax(location)}`}</div>
      </div>
    </>
  ) : physician?.firstName ? (
    <>
      <div className={`m-0 ${className}`}>
        <div className="font-bold">{`${physician.lastName}, ${physician.firstName}`}</div>
        <div className="m-0">{`Billing: ${physician.billingNumber}`}</div>
        <div className="m-0">{`Licensing: ${physician.licensingNumber}`}</div>
      </div>
    </>
  ) : (
    <></>
  );
};
