import { CSSProperties } from "react";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import DateFNSDatePicker from "./DateFNSDatePicker";
import { isNil } from "lodash";

interface DateSelectorProps {
  value?: Date | null;
  onChange?: (value: Date) => void;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  size?: SizeType;
  format?: string;
  allowClear?: boolean;
  showTime?: boolean;
}

export const DateSelector = (props: DateSelectorProps) => {
  const { value, onChange, style, className, size, format = "y-MM-dd", allowClear = false, showTime = false } = props;

  return (
    <DateFNSDatePicker
      value={value}
      style={style}
      onChange={(e: any) => (isNil(onChange) ? null : onChange(e))}
      format={format}
      className={className}
      size={size}
      allowClear={allowClear}
      showTime={showTime}
    />
  );
};
