import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-button-01 text-button-fg-01 shadow hover:bg-button-01/90 font-sans font-normal disabled:text-black/75",
        destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90 font-sans font-normal disabled:text-black/75",
        outline:
          "border border-input bg-background shadow-sm hover:bg-primary-hover hover:text-primary-fg-body font-sans font-normal disabled:text-black/75",
        dashed:
          "border border-dashed bg-background shadow-sm hover:bg-primary-hover hover:text-primary-fg-body font-sans font-normal disabled:text-black/75",
        secondary: "bg-[#f4f4f5] text-secondary-foreground shadow-sm hover:bg-[#f4f4f5]]/80 font-sans font-normal disabled:text-black/75",
        ghost: "hover:bg-[#f4f4f5] hover:text-primary-fg-body font-sans font-normal disabled:text-black/75",
        "ghost-no-hover": "font-sans font-normal",
        link: "text-blue-600 underline-offset-4 hover:underline font-sans font-normal disabled:text-black/75",
      },
      size: {
        default: "h-8 px-3 py-2 text-sm",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
        "sm-icon": "size-8 p-0 text-xs",
        "smaller-icon": "size-7 text-xs",
        "icon-fill": "size-5 text-base",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps extends ComponentProps<"button">, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = ({ className, variant, size, asChild = false, ...props }: ButtonProps) => {
  const Comp = asChild ? Slot : "button";
  return (
    <Comp
      className={cn(
        buttonVariants({ variant, size, className })
        // , props.disabled && "text-button-disabled-fg"
      )}
      {...props}
    />
  );
};
Button.displayName = "Button";

export { Button, buttonVariants };
