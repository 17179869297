import { APIPatientMerge, EMRPatient, PatientAlternateContactInfo } from "@/lib/types/patient";
import customFetch from "./customFetch";
import { LesionItemType } from "@/lib/types/aiVerification";

type PatientHCNResponseObject = {
  patient?: EMRPatient;
  message: string;
  error?: string;
};

const getPatientsElastic = async (query: string): Promise<EMRPatient[]> => {
  const res = await customFetch(`/patient/search`, { body: { query }, method: "POST" });
  return res.patients.hits.hits.map((v: { _source: EMRPatient }) => v?._source) as EMRPatient[];
};

const getPatientDataById = async (patientId: number): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient/patientId/${patientId}`);
  return patient;
};

const updatePatient = async (patientData: EMRPatient): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient`, { body: patientData, method: "PUT" });
  return patient;
};

const mergePatient = async (mergeData: APIPatientMerge, patientMergedId: number): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient/patientId/${patientMergedId}/action/merge`, { body: mergeData, method: "POST" });
  return patient;
};

const getPatientLesionHistory = async (patientId: number): Promise<LesionItemType[]> => {
  const { patientLesionHistories } = await customFetch(`/patient/patientId/${patientId}/lesions`);
  return patientLesionHistories;
};

const archivePatientLesionHistory = async (noteId: number): Promise<string> => {
  const { message } = await customFetch(`/patient/lesion/${noteId}/action/archive`, { method: "PATCH" });
  return message;
};

const getPatientAlternateContactsInfo = async (patientId: number): Promise<PatientAlternateContactInfo[]> => {
  const { patientAlternateContacts } = await customFetch(`/patient/patientId/${patientId}/altContacts/info`);
  return patientAlternateContacts;
};

const getPatientByHCN = async (patientHCN: string | undefined, patientVC: string | undefined): Promise<PatientHCNResponseObject> => {
  // undefined in the input props on purpose as it will still run as 404. its stupid, i know
  const response = await customFetch(`/patient/patientHCN/${patientHCN}/patientVC/${patientVC}`, undefined, undefined, {
    dontHandleResponse: true,
    responseType: "untouched",
  });
  const data = await response.json();
  return data;
};

const createPatient = async (patientData: EMRPatient): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient`, { body: patientData, method: "POST" });
  return patient;
};

const patientAPI = {
  getPatientsElastic,
  getPatientDataById,
  updatePatient,
  getPatientLesionHistory,
  archivePatientLesionHistory,
  getPatientAlternateContactsInfo,
  mergePatient,
  getPatientByHCN,
  createPatient,
};

export default patientAPI;
