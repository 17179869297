import { AddressBookPhysician } from "@/lib/types/addressBook";
import { baseAPIClient } from "./BaseAPIClient";

const getPhysiciansElastic = (query: string, callback: Function) => {
  callback(baseAPIClient.post("/physician/search", { query }));
};

const getAddressBookPhysicians = async () => {
  const resp = await baseAPIClient.get("/physician");

  return resp;
};

const getAddressBookPhysician = async (id: string) => {
  const resp = await baseAPIClient.get(`/physician/${id}`);

  return resp;
};

const updateAddressBookPhysician = (physician: AddressBookPhysician, callback: Function) => {
  callback(baseAPIClient.post("/physician/update", physician));
};

const createAddressBookPhysician = (physician: AddressBookPhysician, callback: Function) => {
  callback(baseAPIClient.post("/physician/create", physician));
};

const physicianAPIClient = {
  getPhysiciansElastic,
  getAddressBookPhysicians,
  getAddressBookPhysician,
  updateAddressBookPhysician,
  createAddressBookPhysician,
};

export default physicianAPIClient;
