// TODO; make these enums or something

export const healthcardProvinceOptions: string[] = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
  "Medavie Blue Cross",
  "Private or other insurance",
  "No insurance and will pay directly",
];

export const userProvinceOptions: string[] = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

export const userProvinceMap: { [key: string]: string } = {
  // keys are elements of userProvinceOptions; TODO: add this to typescript
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  Nunavut: "NU",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "QC",
  Saskatchewan: "SK",
  Yukon: "YT",
};

export const AppointmentStatusMap: { [key: string]: string } = {
  pending: "Pending",
  needs_patient_confirmation: "Needs Patient Confirmation",
  confirmed: "Confirmed",
  arrived: "Arrived",
  noshow: "No Show",
  clinic_rescheduled: "Clinic Rescheduled",
  patient_rescheduled: "Patient Rescheduled",
  patient_cancelled: "Patient Cancelled",
  clinic_cancelled: "Clinic Cancelled",
  patient_unreachable: "Patient Unreachable",
  reschedule_pending: "Reschedule Pending",
};

export const AppointmentSettingMap: { [key: string]: string } = {
  virtual: "Virtual",
  "in-person": "In-Person",
  "phone-call": "Phone Call",
};

// export const VideoConferenceLinkOptions: string[] = ["https://doxy.me/getskinhelp", "https://doxy.me/getskinhelp2", "https://doxy.me/getskinhelp3"];

export type GenericDropdownDataModel = {
  label: string;
  value: string;
};

export type DocumentTypesDropdownDataModel = GenericDropdownDataModel & {
  quickFilterType: string;
};

// export const physicianConcernOptions: string[] = [
//   "Skin Lesion/Skin Cancer",
//   "Acne",
//   "Carpal Tunnel",
//   "Cosmetic",
//   "Cyst",
//   "Hair Loss",
//   "Rosacea",
//   "Melasma",
//   "Dermatitis",
//   "Psoriasis",
//   "Wound Check and Pathology Follow-up",
//   "Ulcer",
//   "Vitiligo",
//   "Other",
// ];

export const userTypeOptions: string[] = ["admin", "doctor", "non_user"];

export const AppointmentTypeMap: { [key: string]: string } = {
  "external-referral": "External Referral",
  "internal-referral": "Internal Referral",
  "follow-up": "Follow-up",
  "getskinhelp-app": "GetSkinHelp App",
  "getskinhelp-website": "GetSkinHelp Website",
  "getskinhelp-portal": "GetSkinHelp Portal",
};

export const SchedulerTimeSlotTypeMap: { [key: string]: string } = {
  "external-referral": "External Referral",
  "internal-referral": "Internal Referral",
  "follow-up": "Follow-up",
  "getskinhelp-app": "GetSkinHelp App",
  "getskinhelp-website": "GetSkinHelp Website",
  "getskinhelp-portal": "GetSkinHelp Portal",
  "do-not-book": "Do not book",
};

export const AppointmentLetterNameMap: { [key: string]: string } = {
  needs_patient_confirmation: "Appointment Letter",
  confirmed: "Appointment Letter",
  clinic_rescheduled: "Clinic Rescheduled Letter",
  patient_rescheduled: "Patient Rescheduled Letter",
  noshow: "No Show Letter",
  patient_unreachable: "Patient Unreachable Letter",
  patient_cancelled: "Appointment Cancelled Letter",
  clinic_cancelled: "Appointment Cancelled Letter",
};

export const AddressBookStatusTypeMap: { [key: string]: string } = {
  approved: "Approved",
  suggested: "Suggested",
  archived: "Archived",
};

// export const documentTypeList: string[] = [
//   "Images (Fax)",
//   "Images (Virtual Chart)",
//   "Letters (Inbound)",
//   "Letters (Outbound)",
//   "Patient Portal Submission",
//   "Patient Intake Forms",
//   "Patient Note",
//   "Patient Note (Legacy)",
//   "Prescriptions",
//   "Requisitions",
//   "Referral (Inbound)",
//   "Referral (Outbound)",
//   "Results (Imaging)",
//   "Results (Labs)",
//   "Results (Pathology)",
//   "Other",
// ];

export const calendarStatusColours = {
  pending: "#FFA94D",
  needs_patient_confirmation: "#66D9E8",
  confirmed: "#B2C91E",
  arrived: "#339AF0",
  noshow: "#798189",
  clinic_rescheduled: "#AE3EC9",
  patient_rescheduled: "#9775FA",
  patient_cancelled: "#F03E3E",
  clinic_cancelled: "#F03E3E",
  patient_unreachable: "#FFA94D",
  reschedule_pending: "#F9E63A",
};

export const calendarStatusTextColours = {
  pending: "#131523",
  needs_patient_confirmation: "#131523",
  confirmed: "#131523",
  arrived: "#FDFDFD",
  noshow: "#FDFDFD",
  clinic_rescheduled: "#FDFDFD",
  patient_rescheduled: "#131523",
  patient_cancelled: "#FDFDFD",
  clinic_cancelled: "#FDFDFD",
  patient_unreachable: "#131523",
  reschedule_pending: "#131523",
};

export const insuranceTypeColours = {
  Ontario: "#5b9482",
  Alberta: "#0b73b8",
  "Medavie Blue Cross": "#12a2ee",
  "Private or other insurance": "#72a957",
  "No insurance and will pay directly": "#72a957",
  "British Columbia": "#a6a6a6",
  Manitoba: "#a6a6a6",
  "New Brunswick": "#a6a6a6",
  "Newfoundland and Labrador": "#a6a6a6",
  "Northwest Territories": "#a6a6a6",
  "Nova Scotia": "#a6a6a6",
  Nunavut: "#a6a6a6",
  "Prince Edward Island": "#a6a6a6",
  Quebec: "#a6a6a6",
  Saskatchewan: "#a6a6a6",
  Yukon: "#a6a6a6",
};

export const schedulerAppointmentDurationMap: { [key: string]: number } = {
  "5": 5,
  "10": 10,
  "15": 15,
  "20": 20,
  "25": 25,
  "30": 30,
};

export const PreferredContactMethodStrings: { [index: string]: string } = {
  busPhoneCall: "Cell Phone Call",
  busPhoneText: "Cell Phone Text",
  email: "Email",
  homePhoneCall: "Home Phone Call",
};

export const enum OrderForms {
  LifelabsSurgicalPathology = "surgical-pathology",
  DynacareDermatopathology = "dynacare-dermatopathology",
  LifelabsDermatopathology = "lifelabs-dermatopathology",
  OntarioGeneralTest = "ontario-general-test",
  SkinopathyDiagnosticImaging = "image-visualization",
  OntarioLaboratory = "ontario-laboratory",
}

export const orderFormNameMap: { [key: string]: string } = {
  "surgical-pathology": "Lifelabs - Surgical Pathology Requisition",
  "dynacare-dermatopathology": "Dynacare - Dermatopathology Requisition",
  "lifelabs-dermatopathology": "Lifelabs - Dermatopathology Requisition",
  "ontario-general-test": "Ontario - General Test Requisition",
  "image-visualization": "Skinopathy - Diagnostic Imaging",
  "ontario-laboratory": "Ontario - Laboratory Requisition",
};

export const presetOrderFormNamesMap: { [key: string]: string } = {
  hair_loss: "Hair Loss - Lab",
  accutane: "Accutane - Lab",
  biologic_lab: "Biologic - Lab",
  biologic_imaging: "Biologic - Imaging",
  puritis: "Puritis - Lab",
  spironolactone: "Spironolactone - Lab",
  vitiligo: "Vitiligo - Lab",
  hormone: "Hormone - Lab",
};

export const enum OrderStatuses {
  created = "created",
  resultReceived = "result_received",
  resultViewed = "result_viewed",
  reconciled = "reconciled",
}

export const orderStatusNameMap: { [key: string]: string } = {
  created: "Ordered",
  result_received: "To Be Reviewed",
  result_viewed: "Results Viewed",
  reconciled: "Reconciled",
};

// TODO: Expand list?
export const OntarioGeneralTestMenu: string[] = [
  "Gastroenteritis Virus Detection (Molecular)",
  "Nocardia - Aerobic Actinomycetes (Lab to complete)",
  "Mycobacterium",
  "Mycobacterium culture",
  "Mycobacterium - PCR for MTBC/MAC",
  "Urea/Myco culture",
  "Ureaplasma / Mycoplasma",
  "Urogenital Ureaplasma / Mycoplasma",
  "Mycoplasma pneumoniae - Respiratory PCR",
  "Arthropod or ectoparasite identification",
  "Scabies or mite identification",
  "Pediculosis or lice identification",
  "Tungiasis or flea identification",
  "Myiasis or maggot identification",
  "16S rDNA",
  "Bartonella or Cat Scratch Disease",
  "Chlamydia pneumoniae",
  "Chlamydia trachomatis / CT culture",
  "Chlamydia trachomatis (CT) Lymphogranuloma venereum (LGV) testing",
  "Chlamydia trachomatis/ Neisseria gonorrhoeae (CT/NG) NAAT",
  "Chlamydia / Chlamydophila psittaci",
  "Chlamydia / Chlamydophila psittaci PCR",
  "Psittacosis",
  "Clostridioides difficile",
  "Clostridioides difficile culture and PFGE typing",
  "Clostridium perfringens Screen Test",
  "Cytomegalovirus PCR",
  "Confirmation of CMV DNA detection on newborn screen test.",
  "Cytomegalovirus - CMV IgG and/or CMV IgM",
  "Dengue Virus serology or PCR",
  "Dengue Virus PCR",
  "Diphtheria culture",
  "Diphtheria serology",
  "Enterovirus PCR or Enterovirus culture or EV-D68",
  "Enterovirus PCR or EV-D68",
  "Epstein Barr Virus - EBV Serology",
  "Fungal serology",
  "Fungus culture",
  "Fungus reference identification",
  "Fungus susceptibility",
  "Neisseria gonorrhoeae / GC culture",
  "Chlamydia trachomatis/ Neisseria gonorrhoeae (CT/NG) NAAT",
  "Yellow Fever Virus serology",
  "Yellow Fever Virus PCR",
  "Viral Haemorrhagic Fever (VHF) testing including for Ebola virus disease",
  "Hantavirus serology",
  "Hantavirus PCR",
  "Helicobacter pylori serology (H. pylori)",
  "Hepatitis A Genotyping",
  "Hepatitis A",
  "Hepatitis B Virus (HBV) Genotyping",
  "Hepatitis B Serology",
  "Hepatitis C (HCV) Serology",
  "Hepatitis D Virus (HDV)",
  "Hepatitis E Virus (HEV)",
  "Herpes B Virus PCR",
  "Herpes B Virus IgG Serolog",
  "Herpes Simplex Virus",
  "HSV resistance testing",
  "Herpes Simplex 1 & 2 (Type Specific IgG)",
  "HTLV I / HTLV II Serology",
];
