import axios from "axios";
import { getSession } from "next-auth/react";

const ApiClient = () => {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_DOMAIN,
  });

  instance.interceptors.request.use(async (request) => {
    if (request.headers.Authorization) {
      return request;
    }
    const session = await getSession();
    if (session) {
      request.headers.Authorization = `Bearer ${session.token.accessToken}`;
      if (request.headers["Content-Type"] === undefined) {
        request.headers["Content-Type"] = "application/json";
      }
    }
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(`error`, error);
    }
  );

  return instance;
};

export const baseAPIClient = ApiClient();
