import { EMRPhysician } from "@/lib/types/physician";
import customFetch from "./customFetch";
import { PatientFileType } from "@/lib/types/encounterNote";
import { EMRUser } from "@/lib/types/user";

const getUser = async (): Promise<EMRUser> => {
  const { user } = await customFetch(`/user/profile`);
  return user;
};

const getUsers = async (): Promise<EMRPhysician[]> => {
  const { users } = await customFetch("/user");
  return users;
};

const getDoctors = async (): Promise<EMRPhysician[]> => {
  const { doctors } = await customFetch("/user/doctors");
  return doctors;
};

const getNewDoctorFilesList = async (): Promise<PatientFileType[]> => {
  const { files } = await customFetch(`/user/files`);
  return files;
};

const updateUserProfile = async (data: EMRUser): Promise<EMRUser> => {
  const { user } = await customFetch("/user", { body: data, method: "PUT" });
  return user;
};

const userAPI = {
  getUser,
  getUsers,
  getDoctors,
  getNewDoctorFilesList,
  updateUserProfile,
};

export default userAPI;
